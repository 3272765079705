<template>
  <div class="instantOrder">

    <el-card class="box-card father-card"
             shadow="never">
      <el-row class="btns">
        <el-form ref="form"
                 :model="form"
                 inline>
          <el-form-item prop="orderNo" label="订单编号">
            <el-input v-model="form.orderNo"
                      placeholder="请输入订单编号"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="memberName" label="姓名">
            <el-input v-model="form.memberName"
                      placeholder="请输入用户姓名"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="memberMobile" label="电话号码">
            <el-input v-model="form.memberMobile"
                      placeholder="请输入手机号码"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="startAddress" label="起始地址">
            <el-input v-model="form.startAddress"
                      placeholder="请输入起始地址"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="endAddress"  label="结束地址">
            <el-input v-model="form.endAddress"
                      placeholder="请输入结束地址"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="region" label="机构">
            <el-select @change="mechanismChange"
                       v-model="form.region"
                       placeholder="请选择机构名称"
                       clearable>
              <el-option v-for="(regionItem, index) in orderCompanyList"
                         :key="index"
                         :label="regionItem.companyName"
                         :value="regionItem.uuid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="carNum" label="车辆">
            <el-select filterable
                       v-model="form.carNum"
                       placeholder="请先选择机构，再选择车辆"
                       clearable>
              <el-option v-for="(item, index) in carList"
                         :key="index"
                         :label="item.carNum"
                         :value="item.carNum"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="orderType" label="订单类型">
            <el-select v-model="form.orderType"
                       placeholder="请选择订单类型"
                       clearable>
              <el-option label="立即用车"
                         value="0"></el-option>
              <el-option label="预约用车"
                         value="1"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-row> -->

          <el-form-item prop="status" label="支付状态">
            <el-select v-model="form.status"
                       placeholder="请选择支付状态"
                       clearable>
              <el-option v-for="(statusItme, index) in paymentStatusList"
                         :key="index"
                         :label="statusItme.text"
                         :value="statusItme.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="transportTypeId" label="转运类型">
            <el-select v-model="form.transportTypeId" placeholder="请选择转运类型">
				<el-option :label="item.transportTypeName" :value="item.uuid"
				v-for="(item, index) in TransportType" :key="index"></el-option>
			</el-select>
          </el-form-item>
          <el-form-item label="订单开始结束时间">
            <WTimer ref="WTimer" v-model="form" :startEnd="{start:'startOrderDate',end:'endOrderDate'}" />
          </el-form-item>
          <!-- <el-form-item label="订单开始时间"
                        prop="disinfectTime">
            <el-date-picker v-model="form.startOrderDate"
                            type="date"
                            placeholder="开始时间"
                            label-width="10px"
                            clearable
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到"
                        prop="disinfectTime">
            <el-date-picker v-model="form.endOrderDate"
                            type="date"
                            placeholder="结束时间"
                            clearable
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item>
            <el-button type="success"
                       icon="el-icon-search"
                       @click="search()">搜索</el-button>
            <el-button type="primary"
                       icon="el-icon-delete"
                       @click="reset()">重置</el-button>
            <el-button v-permission="['/orderRemark']" v-if='isOrderCompany' type="primary"
                       icon="el-icon-edit-outline"
                       @click="addremark()">编辑备注</el-button>

            <el-button v-permission="['/addSurcharge']" v-if='isOrderCompany' type="primary"
                       icon="el-icon-edit-outline"
                       @click="addremark(1)">补充收款</el-button>
            <el-button v-permission="['/orderExport']" type="info"
                       icon="el-icon-share"
                       @click="toExcel()">导出</el-button>
          </el-form-item>
          <!-- </el-row> -->
          <!-- 按钮区域 -->
        </el-form>
      </el-row>
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table ref="multipleTable"
                  :data="List"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :summary-method="getSummaries"
                  show-summary
                  highlight-current-row
                  @current-change="addremarks"
                  stripe
                  border
                  :header-cell-style="rowClass">
          <!-- <el-table-column prop="orderNo" label="展开" align="center"  type="expand" >
			  <template slot-scope="scope" >
				 {{scope.row.remark}}
				  </template>
		 </el-table-column> -->
          <el-table-column prop="orderNo"
                           label="订单编号"
                           align="center"></el-table-column>
          <el-table-column prop="orderDate"
                           label="订单时间"
                           align="center"></el-table-column>
          <el-table-column prop="orderCompanyName"
                           label="所属机构"
                           align="center"></el-table-column>
          <!-- <el-table-column prop="carNum"
                           label="车牌号"
                           align="center"></el-table-column> -->
          <el-table-column prop="applicationName"
                           label="订单来源"
                           align="center">
            <!-- <template slot-scope="scope">
							<el-tag type="success" effect="dark">{{scope.row.applicationName}}</el-tag>
						</template> -->
          </el-table-column>
          <!-- orderType -->
          <el-table-column prop="orderType"
                           label="订单类型"
                           align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.orderType === '0'">{{ "立即用车" }}</div>
              <div v-else>
                <!-- {{ "预约用车" }} -->
                {{ scope.row.orderType === "0" ? "" : scope.row.orderReserveDate }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="orderReserveDate"
                           label="预约时间"
                           align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.orderType === "0" ? "" : scope.row.orderReserveDate }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="memberName"
                           label="下单用户"
                           align="center"></el-table-column>
          <el-table-column prop="memberMobile"
                           label="用户手机"
                           align="center"></el-table-column>
          <el-table-column prop="transferVehicle"
                           label="转运类型"
                           align="center"></el-table-column>

          <el-table-column prop="orderPredictFee"
                           label="预估金额"
                           align="center">
            <!-- 	<template slot-scope="scope">
							<el-tag effect="dark" type="success">{{ scope.row.orderPredictFee}}</el-tag>
						</template> -->
          </el-table-column>
          <el-table-column prop="predictMile"
                           label="预估里程"
                           align="center">
            <!-- <template slot-scope="scope">
							<el-tag effect="dark" type="primary">{{ scope.row.orderPredictPay}}</el-tag>
						</template> -->
          </el-table-column>
          <el-table-column prop="orderFactMile"
                           label="实跑里程"
                           align="center"></el-table-column>
          <el-table-column prop="orderPredictPayActual"
                           label="已付预付费"
                           align="center">
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.orderPredictPayActual || 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="orderFactFeeActual"
                           label="已付结单费"
                           align="center">
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.orderFactFeeActual || 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="surcharge"
                           label="补充收款"
                           align="center">
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.surcharge || 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="sumPayFee"
                           label="实付总付费"
                           align="center">
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.sumPayFee || 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="bargainFee"
                           label="议价费用"
                           align="center"></el-table-column>
          <el-table-column prop="bargainStatus"
                           label="是否议价"
                           align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.bargainStatus === '0'">否</div>
              <div v-else-if="scope.row.bargainStatus === '1'">是</div>
              <!-- 	<el-tag effect="dark" v-if="scope.row.bargainStatus === '0'">{{ "否" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.bargainStatus === '1'">{{ "是" }}
							</el-tag> -->
            </template>
          </el-table-column>
          <!-- status -->
          <el-table-column prop="status"
                           label="状态"
                           align="center"
                           min-width="100px">
            <template slot-scope="scope">
              <!-- <el-tag effect="dark" v-if="scope.row.status === '0'">{{ "新增" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '1'">{{ "已派单" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '2'">{{ "已接单" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '3'">{{ "变更" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '4'">{{ "已支付" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '5'">{{ "取消订单" }}
							</el-tag>
							<el-tag effect="dark" type="primary" v-else-if="scope.row.status === '6'">{{ "全额退款" }}
							</el-tag>
							<el-tag effect="dark" type="info" v-else-if="scope.row.status === '7'">{{ "完成" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '8'">{{ "待付预付费" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '9'">{{ "到达出发地" }}
							</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === '10'">{{ "到达目的地" }}
							</el-tag>
							<el-tag effect="dark" type="warning" v-else-if="scope.row.status === '11'">{{ "非正常完成" }}
							</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status === '12'">{{ "已退预付" }}
							</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status === '13'">{{ "已退结单" }}
							</el-tag> -->

              <div v-if="scope.row.status === '0'">{{ "新增" }}</div>
              <div v-else-if="scope.row.status === '1'">{{ "已派单" }}</div>
              <div v-else-if="scope.row.status === '2'">{{ "已接单" }}</div>
              <div v-else-if="scope.row.status === '3'">{{ "变更" }}</div>
              <div v-else-if="scope.row.status === '4'">{{ "已支付" }}</div>
              <div v-else-if="scope.row.status === '5'">{{ "取消订单" }}</div>
              <div v-else-if="scope.row.status === '6'">{{ "全额退款" }}</div>
              <div v-else-if="scope.row.status === '7'">{{ "完成" }}</div>
              <div v-else-if="scope.row.status === '8'">{{ "待付预付费" }}</div>
              <div v-else-if="scope.row.status === '9'">{{ "到达出发地" }}</div>
              <div v-else-if="scope.row.status === '10'">{{ "到达目的地" }}</div>
              <div v-else-if="scope.row.status === '11'">{{ "非正常完成" }}</div>
              <div v-else-if="scope.row.status === '12'">{{ "已退预付" }}</div>
              <div v-else-if="scope.row.status === '13'">{{ "已退结单" }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="轨迹回放" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="ordercha(scope.row)" type="success">查看轨迹</el-button>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="bargainStatus"
                           label="是否开发票"
                           align="center">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="primary"
                         v-if="scope.row.invoice == 0"
                         @click="invoiceClick(scope.row)">
                {{ "否" }}
              </el-button>
              <el-button size="mini"
                         type="success"
                         v-else-if="scope.row.invoice == 1"
                         @click="invoiceClick(scope.row)">{{ "是" }}</el-button>
            </template>
          </el-table-column> -->
          <el-table-column label="详情"
                           align="center"
                           width="200">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="look(scope.row)"
                         type="success">查看详情</el-button>
              <!-- <el-button size="mini"  @click="editQrCode(scope.row)"  type="primary"
                >查看二维码收款</el-button
              > -->
              <el-button size="mini" v-permission="['/isInvoice']"
                         type="primary"
                         v-if='isOrderCompany'
                         @click="invoiceClick(scope.row)">是否开发票</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handlePageNumChange"
                       :current-page="paging.PageNo"
                       :page-sizes="[10, 15, 20, 50]"
                       :page-size="paging.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total"></el-pagination>
        <!-- 订单详情弹出框 -->
        <el-dialog :visible.sync="orderDialogFormVisible"
                   width="72%"
                   class="order-dialog">
          <span slot="title"
                class="dialog-Title">即时订单详情</span>
          <el-form :model="orderForm"
                   inline
                   ref="orderForm">
            <div class="fpddcolor">
              <!--        <div class="fpddcolor-title" style="display: flex; justify-content: space-between;">
                      <div v-if="orderForm.orderType == 0">订单类型：立即用车</div><div v-if="orderForm.orderType == 1">订单类型：预约用车</div>
                      <div v-if="orderForm.status == 1">订单状态：已派单</div>
                      <div v-if="orderForm.status == 2">订单状态：已接单</div>
                      <div v-if="orderForm.status == 3">订单状态：变更</div>
                      <div v-if="orderForm.status == 4">订单状态：已支付</div>
                      <div v-if="orderForm.status == 5">订单状态：取消订单</div>
                      <div v-if="orderForm.status == 6">订单状态：全额退款</div>
                      <div v-if="orderForm.status == 7">订单状态：完成</div>
                      <div v-if="orderForm.status == 8">订单状态：代付预付费</div>
                      <div v-if="orderForm.status == 9">订单状态：到达出发地</div>
                      <div v-if="orderForm.status == 10">订单状态：到达目的地</div>
                      <div v-if="orderForm.status == 11">订单状态：非正常完成</div>
                      <div v-if="orderForm.status == 12">订单状态：已退预付</div>
                      <div v-if="orderForm.status == 13">订单状态：已退结单</div>
                      </div> -->
              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="padding-left: 10px">
                  <img src="../../images/us.png" />客户信息
                </div>
              </div>
              <div style="background-color: #fff; display: flex">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    下单人姓名
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.memberName }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="background-color: #f3f6fa; padding: 10px 20px; width: 100px">
                    手机号码
                  </div>
                  <div style="padding: 10px 20px">{{ orderForm.memberMobile }}</div>
                </div>
              </div>
              <div>
                <div class="daijiaocolor">
                  <div class="daijiaocolor-left"
                       style="padding-left: 10px">
                    <img src="../../images/us.png" />患者信息
                  </div>
                </div>
                <div style="
                    display: flex;
                    background-color: #fff;
                    border-bottom: #f3f6fa 2px solid;
                  ">
                  <div class="cflex">
                    <div style="
                        background-color: #f3f6fa;
                        padding: 10px;
                        width: 100px;
                        text-align: center;
                      ">
                      患者姓名
                    </div>
                    <div style="padding: 10px 20px; width: 200px">
                      {{ orderForm.patientName || "暂无信息" }}
                    </div>
                  </div>
                  <div class="cflex">
                    <div style="
                        background-color: #f3f6fa;
                        padding: 10px;
                        width: 100px;
                        text-align: center;
                      ">
                      患者年龄
                    </div>
                    <div style="padding: 10px 20px; width: 200px">
                      {{ orderForm.patientAge || "暂无信息" }}
                    </div>
                  </div>
                  <div class="cflex">
                    <div style="
                        background-color: #f3f6fa;
                        padding: 10px;
                        width: 100px;
                        text-align: center;
                      ">
                      患者手机号码
                    </div>
                    <div style="padding: 10px 20px; width: 200px">
                      {{ orderForm.patientMobile || "暂无信息" }}
                    </div>
                  </div>
                </div>
                <div style="display: flex; background-color: #fff">
                  <div class="cflex">
                    <div style="
                        background-color: #f3f6fa;
                        padding: 10px;
                        width: 100px;
                        text-align: center;
                      ">
                      病情描述
                    </div>
                    <div style="padding: 10px 20px">
                      {{ orderForm.patientConditionRemark || "暂无信息" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="padding-left: 10px">
                  <img src="../../fonts/home.png" />订单信息
                </div>
              </div>
              <div class="fpddcolor-in"
                   style="
                  display: flex;
                  background-color: #fff;
                  border-bottom: #f3f6fa 2px solid;
                ">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    下单时间
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.createTime }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    订单来源
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.applicationName }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    订单编号
                  </div>
                  <div style="padding: 10px 20px">{{ orderForm.orderNo }}</div>
                </div>
              </div>
              <div class="fpddcolor-in"
                   style="
                  display: flex;
                  background-color: #fff;
                  border-bottom: #f3f6fa 2px solid;
                ">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    出发地医院
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.startHospName }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    科室
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.startDepartment }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    床位
                  </div>
                  <div style="padding: 10px 20px">{{ orderForm.startBed }}</div>
                </div>
              </div>
              <div class="fpddcolor-in"
                   style="
                  display: flex;
                  background-color: #fff;
                  border-bottom: #f3f6fa 2px solid;
                ">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    转运类型
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.transferVehicle }}
                  </div>
                </div>

                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    订单类型
                  </div>
                  <!-- <div style="padding: 10px 20px;">{{orderForm.orderNo}}</div> -->
                  <div style="padding: 10px 20px; width: 200px"
                       v-if="orderForm.orderType == 0">
                    立即用车
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-if="orderForm.orderType == 1">
                    预约用车
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    实跑公里
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.orderFactMile }}
                  </div>
                </div>
              </div>
              <div class="row-style">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">预估金额</div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.orderPredictFee }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">预估预付</div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.orderPredictPay }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">预估公里</div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.predictMile }}
                  </div>
                </div>
              </div>
              <div class="cflex">
                <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">议价费用</div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.bargainFee }}
                </div>
              </div>
              <div class="row-style">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">基础费用</div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-if="orderForm.orderFeeMap">
                    {{ orderForm.orderFeeMap.baseMoney }}
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-else>0</div>
                </div>

                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    搬抬费用
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-if="orderForm.orderFeeMap">
                    {{ orderForm.orderFeeMap.carryMoney }}
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-else>0</div>
                </div>

                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    里程费用
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-if="orderForm.orderFeeMap">
                    {{ orderForm.orderFeeMap.milelMoney }}
                  </div>
                  <div style="padding: 10px 20px; width: 200px"
                       v-else>0</div>
                </div>
              </div>
              <!-- 设备，费用，其他费用table列表 -->
              <div class="row-style cost-style">
                <!-- 设备列表 -->
                <div v-if="tableData.equipmentList&&tableData.equipmentList!=0"
                     class="cost-box">
                  <div class="cflex-lable">费用列表</div>
                  <div>
                    <el-table :data="tableData.equipmentList"
                              show-summary
                              border
                              style="width: 100%"
                              height="180">
                      <el-table-column fixed
                                       prop="medicalsetName"
                                       label="项目名称"> </el-table-column>
                      <el-table-column prop="medicalsetNumber"
                                       label="数量"> </el-table-column>
                      <el-table-column prop="medicalsetMoney"
                                       label="单价"> </el-table-column>
                      <el-table-column prop="medicalsetMoneyTotal"
                                       label="费用合计"> </el-table-column>
                    </el-table>
                  </div>
                </div>
                <!-- 用药列表 -->
                <div v-if="tableData.medicalList&&tableData.medicalList!=0"
                     class="cost-box">
                  <div class="cflex-lable">用药列表</div>
                  <div>
                    <el-table :data="tableData.medicalList"
                              show-summary
                              border
                              height="180"
                              style="width: 100%">
                      <el-table-column fixed
                                       prop="medicalsetName"
                                       label="项目名称"> </el-table-column>
                      <el-table-column prop="medicalsetNumber"
                                       label="数量"> </el-table-column>
                      <el-table-column prop="medicalsetMoney"
                                       label="单价"> </el-table-column>
                      <el-table-column prop="medicalsetMoneyTotal"
                                       label="费用合计"> </el-table-column>
                    </el-table>
                  </div>
                </div>
                <!-- 其他 -->
                <div v-if="tableData.otherFeeList&&tableData.otherFeeList!=0"
                     class="cost-box">
                  <div class="cflex-lable">其他</div>
                  <div>
                    <el-table :data="tableData.otherFeeList"
                              border
                              height="180"
                              style="width: 100%">
                      <el-table-column fixed
                                       prop="dispatchItem"
                                       label="名称"> </el-table-column>
                      <el-table-column prop="dispatchFee"
                                       label="费用"> </el-table-column>
                      <el-table-column prop="remark"
                                       label="备注"> </el-table-column>

                    </el-table>
                  </div>
                </div>
              </div>
              <div class="row-style">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">已付预付</div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.orderPredictPayActual || 0 }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    已付结单
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.orderFactFeeActual || 0 }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">补充收款</div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.surcharge || 0 }}
                  </div>
                </div>
              </div>
              <div class="row-style">
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    订单总付
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.sumPayFee || 0 }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                      background-color: #f3f6fa;
                      padding: 10px;
                      width: 100px;
                      text-align: center;
                    ">
                    退款金额
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ tableData.refundMoney }}<span v-show="tableData.refundMoney">( {{ tableData.refundDesc }})</span>
                  </div>
                </div>

              </div>

              <div class="cflex"
                   style="margin-top:10px;align-items: center">
                <div style="background-color: #f3f6fa; width: 114px; text-align: center">
                  订单备注
                </div>
                <div style="background-color: #fff; padding: 10px; width: 100%">
                  {{ orderForm.remark || "暂无备注信息" }}
                </div>
              </div>
              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="padding-left: 10px">
                  <img src="../../images/carblue.png" />车辆信息
                </div>
                <div>{{ orderForm.orderCompanyName }}</div>
              </div>
              <div style="background-color: #fff; padding: 10px 10px 0 10px">
                <div>
                  <el-row class="dialog-Title">
                    <el-form-item label="车牌号:">
                      <el-input v-model="orderForm.carNum"
                                autocomplete="off"
                                disabled
                                clearable>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="司机名称:">
                      <el-input v-model="orderForm.driverName"
                                autocomplete="off"
                                disabled
                                clearable></el-input>
                    </el-form-item>
                    <el-form-item label="转运类型:">
                      {{ orderForm.transferVehicle }}
                    </el-form-item>
                    <el-form-item label="司机电话:">
                      <el-input v-model="orderForm.driverPhone"
                                autocomplete="off"
                                :disabled="isDisable"
                                clearable></el-input>
                    </el-form-item>
                  </el-row>
                </div>
                <div>
                  <el-row class="dialog-Title">
                    <el-form-item label="随行医生:">
                      {{ orderForm.doctorNameList }}
                    </el-form-item>
                    <el-form-item label="随行护士:">
                      {{ orderForm.nurseNameList }}
                    </el-form-item>
                    <el-form-item label="护送人员:">
                      {{ orderForm.escortNameList }}
                    </el-form-item>
                    <el-form-item label="搬抬人员:">
                      {{ orderForm.raiseNameList }}
                    </el-form-item>
                  </el-row>
                </div>
              </div>

              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="padding-left: 10px">
                  <img src="../../fonts/location@2x.png" />位置信息
                </div>
              </div>
              <div class="fpddcolor-low"
                   style="background-color: #fff; padding: 10px">
                <!-- <div style="color: #0097fe; display: flex; align-items: center">
                  <img src="../../fonts/operation@3x.png"
                       style="margin-right: 10px" />
                  <div style="width: 60px">起始地:</div>
                  <el-input v-model="orderForm.startAddress"
                            autocomplete="off"
                            :disabled="isDisable"
                            clearable></el-input>
                </div> -->

                <div style="color: #0097fe; display: flex; align-items: center;margin-bottom:10px">
                  <img src="../../fonts/operation@3x.png"
                       style="margin-right: 10px" />
                  <div style="width: 60px">起始地:</div>
                  <div class="host-name-box">
                    <div class="orderHospName">
                      <el-input v-model="orderForm.startOrderAddress"
                                autocomplete="off"
                                :disabled="isDisable"
                                clearable></el-input>
                    </div>
                    <div v-show="
                        isEndOrHostAddress(orderForm.startOrderAddress, orderForm.startAddress)
                      "
                         class="endAddress">
                      <el-input v-model="orderForm.startAddress"
                                autocomplete="off"
                                :disabled="isDisable"
                                clearable></el-input>
                    </div>
                  </div>
                </div>

                <div style="color: #ff7f50; display: flex; align-items: center">
                  <img src="../../images/zd.png"
                       style="margin-right: 10px" />
                  <div style="width: 60px">目的地:</div>
                  <div class="host-name-box">
                    <div class="orderHospName">
                      <el-input v-model="orderForm.orderHospName"
                                autocomplete="off"
                                :disabled="isDisable"
                                clearable></el-input>
                    </div>
                    <div v-show="
                        isEndOrHostAddress(orderForm.endAddress, orderForm.orderHospName)
                      "
                         class="endAddress">
                      <el-input v-model="orderForm.endAddress"
                                autocomplete="off"
                                :disabled="isDisable"
                                clearable></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
          <div slot="footer"
               class="dialog-footer">
            <el-button type="primary"
                       @click="orderDialogFormVisible = false">确 认</el-button>
          </div>
        </el-dialog>
        <el-dialog :title="isRemarkOrQrcode?'新增补充收款':'编辑备注'"
                   @close="orderDialogremarkClose"
                   :visible.sync="orderDialogremark"
                   class="order-dialog">
          <el-form :rules="rules"
                   :model="addupremark"
                   inline
                   ref="addupremark">
            <el-row type="flex"
                    justify="space-between"
                    class="order-row">
              <el-col :span="7">
                <el-form-item label="订单编号">
                  <el-input disabled
                            v-model="addupremark.orderNo"
                            v-input-limit
                            show-word-limit
                            autosize>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="司机">
                  <el-input disabled
                            v-model="remakestauts.driverName"
                            v-input-limit
                            show-word-limit
                            autosize>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="车牌号">
                  <el-input disabled
                            v-model="remakestauts.carNum"
                            v-input-limit
                            show-word-limit
                            autosize>
                  </el-input>
                </el-form-item>
              </el-col>

            </el-row>
             <el-row class="order-row"
                    v-if="!isRemarkOrQrcode">
              <el-col :span="7">
                <el-form-item label="出发地医院">
                  <el-input placeholder="请输出发地医院"
                            v-model="remakestauts.startHospName"

                            show-word-limit
                            >
                  </el-input>

                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="科室">
                  <el-input placeholder="请输入科室"
                            v-model="remakestauts.startDepartment"

                            show-word-limit
                            >
                  </el-input>
                </el-form-item>
              </el-col>
               <el-col :span="7">
                <el-form-item label="床位">
                  <el-input placeholder="请输入床位"
                            v-model="remakestauts.startBed"

                            show-word-limit
                            >
                  </el-input>
                </el-form-item>
              </el-col>
             </el-row>
            <el-row class="order-row"
                    v-if="isRemarkOrQrcode">
              <el-col :span="12">
                <el-form-item label="收款金额"
                              prop="payFee">
                  <el-input placeholder="请输入收款金额"
                            v-model="addupremark.payFee"
                            v-input-limit
                            show-word-limit
                            autosize>
                  </el-input>

                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="交易单号"
                              prop="tradeNo">
                  <el-input placeholder="请输入收款详情页面交易单号"
                            v-model="addupremark.tradeNo"
                            v-input-limit
                            show-word-limit
                            autosize>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="order-row">
              <el-form-item label="备注"
                            prop="remark">
                <el-input type="textarea"
                          placeholder="请输入备注内容"
                          v-model="addupremark.remark"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 6}"
                          :rows="2">
                </el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <div v-if="isRemarkOrQrcode">
            <el-link type="warning">tips：单击表单进行编辑。点击重置，进行新增</el-link>
            <el-table ref="singleTable"
                      highlight-current-row
                      @current-change="orderListChange"
                      border
                      :data="payList"
                      style="width: 100%">
              <el-table-column align="center"
                               type="index"
                               width="50"></el-table-column>
              <el-table-column align="center"
                               prop="payFee"
                               label="收款金额"> </el-table-column>
              <el-table-column align="center"
                               prop="tradeNo"
                               label="交易单号"> </el-table-column>
              <el-table-column align="center"
                               prop="payReturn"
                               label="订单来源"> </el-table-column>
              <el-table-column prop="isRefund"
                               label="退款状态"
                               align="center">
                <template slot-scope="scope">
                  <span effect="dark"
                        type="warning"
                        v-if="scope.row.isRefund === '0'">{{ '未退款' }}</span>
                  <span type="info"
                        effect="dark"
                        v-else>{{
                                '已退款'
                            }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="remark"
                               label="备注"> </el-table-column>
              <el-table-column align="center"
                               label="操作">
                <template slot-scope="{row}">
                  <el-button v-if="row.payChannel=='4'"
                             type="danger"
                             icon="el-icon-delete"
                             @click="delQr(row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="simRecordPage.total>0"
                        :total="simRecordPage.total"
                        :page.sync="simRecordPage.current"
                        :limit.sync="simRecordPage.size"
                        @pagination="editQrCode" />
          </div>
          <div slot="footer"
               class="dialog-footer">
            <el-button v-show="!isRemarkOrQrcode"
                       type="primary"
                       @click="orderDialogaddremark()">确 认</el-button>
            <el-button v-show="isRemarkOrQrcode"
                       type="primary"
                       @click="setCurrent()">重置</el-button>
            <el-button v-show="isRemarkOrQrcode"
                       type="primary"
                       @click="qrCodeConfig('addupremark')">确 认</el-button>
          </div>
        </el-dialog>
        <!-- <el-dialog title="二维码收款列表" :visible.sync="dialogVisibleDetails" width="70%">
            <el-table :data="payList" style="width: 100%">
                <el-table-column align="center" prop="payFee" label="收款金额"> </el-table-column>
                <el-table-column align="center" prop="tradeNo" label="交易单号"> </el-table-column>
                <el-table-column align="center" prop="remark" label="备注"> </el-table-column>
            </el-table>
            <pagination v-show="simRecordPage.total>0" :total="simRecordPage.total" :page.sync="simRecordPage.current" :limit.sync="simRecordPage.size" @pagination="editQrCode" />
        </el-dialog> -->
        <!-- 发票 -->
        <el-dialog :visible.sync="invoiceShow"
                   class="order-dialog"
                   width="30%"
                   title="是否开发票">
          <div>
            <span style="margin-right: 20px">开发票:</span>
            <el-select v-model="invoiceValue"
                       placeholder="请选择"
                       @change="invoiceSelect">
              <el-option v-for="item in invoiceOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div slot="footer"
               class="dialog-footer">
            <el-button type="primary"
                       @click="invoiceChange">确 认</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
    <div class="mapContainer"
         id="mapContainer"></div>
  </div>
</template>
<script>
import { isEndOrHostAddress } from '@/utils/addressMet'
import Pagination from '@/components/Pagination'
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'
// import { mapGetters } from 'vuex'
import { isOrderCompany } from '@/utils'
export default {
  name: 'instantOrder',
  components: {
    Pagination,
    WTimer
  },
  //   computed: {
  //     ...mapGetters([
  //       'isOrderCompany'
  //     ])
  //   },
  data () {
    return {
      TransportType: [],
      isOrderCompany: isOrderCompany(),
      editRow: {},
      payList: [],
      dialogVisibleDetails: false,
      simRecordPage: {
        size: 10,
        current: 1,
        total: 0
      },
      rules: {
        payFee: [
          { required: true, message: '请输入收款金额', trigger: 'blur' }
        ],
        tradeNo: [
          { required: true, message: '请输入收款详情页面交易单号', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      },
      tableData: {
        equipmentList: [],
        medicalList: [],
        otherFeeList: []
      },
      isEndOrHostAddress: isEndOrHostAddress,

      List: [], // 表格数据源
      orderCompanyList: [], // 机构列表
      paymentStatusList: [
        {
          status: 0,
          text: '新增'
        }, // 支付状态列表
        {
          status: 1,
          text: '派单'
        },
        {
          status: 2,
          text: '已接单'
        },
        // {
        // 	status: 3,
        // 	text: '变更'
        // },
        // {
        // 	status: 4,
        // 	text: '已支付'
        // },
        {
          status: 5,
          text: '取消订单'
        },
        {
          status: 6,
          text: '全额退款'
        },
        {
          status: 7,
          text: '完成'
        },
        {
          status: 8,
          text: '待付预付费'
        },
        {
          status: 9,
          text: '到达出发地'
        },
        {
          status: 10,
          text: '到达目的地'
        },
        {
          status: 11,
          text: '非正常完成'
        },
        {
          status: 12,
          text: '预付退款'
        },
        {
          status: 13,
          text: '结单退款'
        }
      ],
      form: {
        orderNo: '', // 订单编号
        memberName: '', // 姓名
        memberMobile: '', // 电话号码
        orderType: '', // 订单类型
        status: '', // 支付状态
        region: '', // 机构
        startOrderDate: '', // 开始时间
        endOrderDate: '', // 结束时间
        startAddress: '',
        endAddress: '',
        carNum: '' // 车牌
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderDialogremark: false,
      addupremark: {
        orderNo: '',
        remark: '',
        payFee: '',
        tradeNo: ''
      },
      orderForm: {},
      remakestauts: '', // 控制备注值
      isDisable: true,
      dialogVisible: false,
      centerlongitude: 39.984104, // 地图中心经度,
      centerlatitude: 116.307503, // 地图中心纬度
      carNumber: [],
      driverNumber: [],
      doctor: [],
      escorts: [],
      lifting: [],
      nurse: [],
      invoiceShow: false, // 发票dialog
      invoiceOptions: [
        {
          value: '1',
          label: '是'
        },
        {
          value: '0',
          label: '否'
        }
      ],
      invoiceValue: '', // 是否开发票
      rows: {},
      fileName: '',
      selectedWorkName: {
        companyName: ''
      },
      comName: '',
      carList: [],
      isRemarkOrQrcode: 0 // 0是编辑备注，1是新增二维码收款，2是编辑二维码收款
    }
  },
  created () {
    this.getOrderList()
    this.getorderCompany()
    this.getAllCar()
    // this.getcarList()
  },
  mounted () {
    // 创建地图
  },
  methods: {
    // 获取所有转运类型
    async getAllCar () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoTransportType/getTInfoTransportTypeList', {
        status: 1
      })
      if (res.statusCode !== '200') return this.$message.error('获取转运类型列表失败')
      console.log(res.data.records)
      this.TransportType = res.data.records
    },
    /**
     * 选择机构后，在请求车辆
     */
    mechanismChange (value) {
      this.form.carNum = ''
      if (value) {
        this.getcarList(value)
      } else {
        this.carList = []// 说明除去了机构，则车辆列表清空
      }
    },
    /**
     * 获取车辆列表
     */
    async getcarList (value) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        pageNo: 1,
        pageSize: 1000,
        corpId: value
      })
      this.carList = res.data
      console.log(res.data, '获取车辆列表')
    },
    /**
     * 详情
     */
    async costDetail ({ uuid }) {
      console.log(typeof uuid)
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectOrderDetail', {
        uuid: uuid + ''
      })

      this.tableData = res.data
      console.log(this.tableData, 'sss')
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property == 'surcharge' || column.property == 'sumPayFee' || column.property == 'bargainFee' || column.property == 'orderPredictPayActual' || column.property == 'orderFactFeeActual') {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = sums[index].toFixed(2) + ' 元'
          } else {
            sums[index] = 'N/A'
          }
        }
      })

      return sums
    },
    async scompany (id) {
      this.selectedWorkName = this.orderCompanyList.find((item) => {
        // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.uuid === id
        // 筛选出匹配数据，是对应数据的整个对象
      })
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getOrderList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getOrderList()
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 获取所有实时订单
    async getOrderList () {
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/selectAllOrderList',
        {
          status: this.form.status,
          orderType: this.form.orderType,
          region: this.form.region,
          pageSize: this.paging.pageSize,
          starOrderDate: this.form.startOrderDate,
          endOrderDate: this.form.endOrderDate,
          pageNo: this.paging.PageNo,
          orderNo: this.form.orderNo,
          applicationCode: this.form.applicationCode,
          userName: this.form.memberName,
          userPhone: this.form.memberMobile,
          startAddress: this.form.startAddress,
          endAddress: this.form.endAddress,
          carNum: this.form.carNum
        }
      )
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      console.log(res)
    },
    reset () {
    //   this.form.orderType = ''
    //   this.form.status = ''
    //   this.form.region = ''
    //   this.form.orderNo = ''
    //   this.form.startOrderDate = ''
    //   this.form.endOrderDate = ''
    //   this.form.memberName = ''
    //   this.form.memberMobile = ''
    //   this.form.startAddress = ''
    //   this.form.endAddress = ''
    //   this.form.carNum = ''
      this.$refs.form.resetFields()
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    },
    // 导出订单
    toExcel () {
      if (!this.form.startOrderDate || !this.form.endOrderDate) {
        return this.$message.error('请选择订单开始时间')
      }
      var date = new Date(this.form.startOrderDate)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m + '-' + d
      var daten = new Date(this.form.endOrderDate)
      var yn = daten.getFullYear()
      var mn = daten.getMonth() + 1
      mn = mn < 10 ? '0' + mn : mn
      var dn = daten.getDate()
      dn = dn < 10 ? '0' + dn : dn
      const endtime = yn + '-' + mn + '-' + dn
      if (
        this.selectedWorkName.companyName &&
        this.form.startOrderDate &&
        this.form.endOrderDate
      ) {
        this.fileName = this.selectedWorkName.companyName + '实时订单汇表'
        this.exportExcel(this.fileName + time + '到' + endtime)
      } else if (
        this.selectedWorkName.companyName &&
        this.form.startOrderDate == '' &&
        this.form.endOrderDate == ''
      ) {
        this.fileName = this.selectedWorkName.companyName + '实时订单汇表'
        this.exportExcel(this.fileName)
      } else if (
        this.selectedWorkName.companyName &&
        this.form.startOrderDate &&
        this.form.endOrderDate == ''
      ) {
        this.fileName = this.selectedWorkName.companyName + '实时订单汇表'
        this.exportExcel(this.fileName + time)
      } else if (
        this.selectedWorkName.companyName &&
        this.form.endOrderDate &&
        this.form.startOrderDate == ''
      ) {
        this.fileName = this.selectedWorkName.companyName + '实时订单汇表'
        this.exportExcel(this.fileName + endtime)
      } else {
        this.fileName = '实时订单总汇表'
        this.exportExcel(this.fileName)
      }
    },
    async exportExcel (value) {
      if (this.selectedWorkName.companyName) {
        this.comName = this.selectedWorkName.companyName
      } else {
        this.comName = '' // 全机构
      }
      const data = {
        status: this.form.status,
        region: this.form.region,
        pageSize: this.paging.pageSize,
        companyName: this.comName,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        starOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        orderType: this.form.orderType,
        userName: this.form.memberName,
        userPhone: this.form.memberMobile,
        startAddress: this.form.startAddress,
        endAddress: this.form.endAddress,
        carNum: this.form.carNum,
        transportTypeId: this.form.transportTypeId

      }
      // http://192.168.123.190:9000
      await this.$http
        .post('/userManageServer/pay/ToExcel', data, {
          responseType: 'blob'
        })
        .then((res) => {
          console.log(res.data)
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          })
          // 第一种
          // '订单汇表.xlsx'
          const fileName = value
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        })
    },
    // 修改（添加）备注 弹出框
    addremarks (e) {
      if (e) {
        console.log(e, 'ee')
        this.remakestauts = JSON.parse(JSON.stringify(e))
      }
    },
    // 关闭弹窗
    orderDialogremarkClose () {
      //   console.log(this.$refs.addupremark, 11)
      this.$refs.addupremark.resetFields()
    },
    /**
     * @params type 0是编辑备注，1是补充收款,2是编辑
     */
    addremark (type) {
      if (!this.remakestauts) {
        return this.$message.error('请选择您要编辑的订单')
      }
      if (type) {
        if (this.remakestauts.status != 7 && this.remakestauts.status != 11) return this.$message.error('请在订单结束后再操作')
      }

      const rs = this.remakestauts
      this.isRemarkOrQrcode = type
      if (this.remakestauts) {
        this.orderDialogremark = true
        this.addupremark.orderNo = rs.orderNo
        this.addupremark.remark = this.isRemarkOrQrcode ? '' : rs.remark
      } else {
        return this.$message.error('请选择您要编辑的订单')
      }
      if (this.isRemarkOrQrcode) {
        this.editQrCode(rs)
      }
    },
    // 修改（添加）备注
    async orderDialogaddremark () {
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/updateTinfoOrderRemark',
        {
          orderNo: this.addupremark.orderNo,
          remark: this.addupremark.remark,
          startHospName: this.remakestauts.startHospName,
          startDepartment: this.remakestauts.startDepartment,
          startBed: this.remakestauts.startBed
        }
      )
      if (res.statusCode !== '200') return this.$message.error('编辑失败')
      this.orderDialogremark = false
      this.getOrderList()
      return this.$message.success('编辑成功')
    },
    /**
     * @params 二维码收款订单列表
     */
    async editQrCode (row) {
      console.log(row, 'row')
      //   this.isRemarkOrQrcode = 2
      this.dialogVisibleDetails = true
      const { data: res } = await this.$http.post(
        '/pmService/tInfoOrderPay/getTInfoOrderPayList',
        {
          pageSize: this.simRecordPage.size,
          pageNo: this.simRecordPage.current,
          payType: 2,
          orderNo: this.remakestauts.orderNo,
          status: 1
        }
      )
      if (res.statusCode !== '200') return this.$message.error('获取失败')
      this.payList = res.data.records
      console.log(res, 'resres')
      this.simRecordPage.total = res.data.total
    },
    /**
     *  @params 补充收款新增或者编辑列表
     */
    qrCodeConfig (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const url = this.isRemarkOrQrcode == 1 ? '/pmService/tInfoOrderPay/addOrderPayByCode' : '/pmService/tInfoOrderPay/updateTradeNo'
          const { data: res } = await this.$http.post(
            url,
            {
              orderNo: this.addupremark.orderNo,
              remark: this.addupremark.remark,
              payFee: Number(this.addupremark.payFee),
              tradeNo: this.addupremark.tradeNo,
              uuid: this.isRemarkOrQrcode == 1 ? undefined : this.editRow.uuid,
              createBy: sessionStorage.getItem('userId')
            }
          )
          this.$refs[formName].resetFields()
          if (res.statusCode !== '200') return this.$message.error('编辑失败')
          this.orderDialogremark = false
          this.editQrCode(this.remakestauts)
          this.getOrderList()
          return this.$message.success('编辑成功')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /**
    *  @params 删除二维码列表
    */
    delQr (row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete(
          '/pmService/tInfoOrderPay/deleteById?uuid=' + row.uuid
        )
        this.$refs.addupremark.resetFields()
        if (res.statusCode !== '200') return this.$message.error('删除失败')
        this.editQrCode(this.remakestauts)
        this.getOrderList()
        return this.$message.success('删除成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    /**
     * @params  二维码收款列表选择行
     */
    orderListChange (row) {
      if (!row) return false
      const { remark, orderNo, payFee, tradeNo } = row
      this.editRow = row
      this.addupremark.remark = remark
      this.addupremark.orderNo = orderNo
      this.addupremark.payFee = payFee
      this.addupremark.tradeNo = tradeNo
      this.isRemarkOrQrcode = 2
      console.log(row)
    },
    // 重置取消单选
    setCurrent (row) {
      this.$refs.singleTable.setCurrentRow(row)
      this.isRemarkOrQrcode = 1
      this.addupremark.remark = ''
      this.addupremark.payFee = ''
      this.addupremark.tradeNo = ''
    },
    handleClose () { },
    // 查看轨迹
    // ordercha(e){

    // },

    // 点击搜索按钮触发
    async search () {
      // var date = new Date(this.form.startOrderDate)
      // var y = date.getFullYear()
      // var m = date.getMonth() + 1
      // m = m < 10 ? ('0' + m) : m
      // var d = date.getDate()
      // d = d < 10 ? ('0' + d) : d
      // const time = y + '-' + m + '-' + d
      // var daten = new Date(this.form.endOrderDate)
      // var yn = daten.getFullYear()
      // var mn = daten.getMonth() + 1
      // mn = mn < 10 ? ('0' + mn) : mn
      // var dn = daten.getDate()
      // dn = dn < 10 ? ('0' + dn) : dn
      // const etime = yn + '-' + mn + '-' + dn
      // var statime = ""
      // var endtime = ""
      // if (time != "NaN-NaN-NaN" && time != "") {
      // 	statime = time
      // }
      // if (etime != "NaN-NaN-NaN" && etime != "") {
      // 	endtime = etime
      // }
      // 判断有输入账号
      this.paging.PageNo = 1
      // if (this.form.orderNo === '' && this.form.applicationCode === '') return this.$message.error('请输入搜索内容')
      // 调接口，进行查询
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/selectAllOrderList',
        {
          status: this.form.status + '',
          orderType: this.form.orderType + '',
          region: this.form.region + '',
          pageSize: this.paging.pageSize,
          starOrderDate: this.form.startOrderDate,
          endOrderDate: this.form.endOrderDate,
          pageNo: this.paging.PageNo,
          orderNo: this.form.orderNo,
          applicationCode: this.form.applicationCode,
          userName: this.form.memberName,
          userPhone: this.form.memberMobile,
          startAddress: this.form.startAddress,
          endAddress: this.form.endAddress,
          carNum: this.form.carNum,
          transportTypeId: this.form.transportTypeId
        }
      )
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      console.log(this.total)
    },
    // 点击查看详情按钮触发
    look (v) {
      this.getAllCarNumber(v)
      this.getAlldriverNumber(v)
      this.getAlldoctor(v)
      this.getAllnurse(v)
      this.getAlllifting(v)
      this.getAllEscorts(v)
      this.costDetail(v)
      this.orderForm = v
      this.orderDialogFormVisible = true
      console.log(this.orderForm, 'this.orderForm')
    },

    // 获取车牌号--调试完成
    async getAllCarNumber (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleList',
        {
          corpId: v.orderCompany
        }
      )
      if (res.result !== 'true') return this.$message.error('获取车牌号列表失败')
      this.carNumber = res.data
      // console.log(this.carNumber);
    },
    // 获取司机--调试完成
    async getAlldriverNumber (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDriver/getTInfoDriverList',
        {
          companyId: v.orderCompany
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取司机失败')
      this.driverNumber = res.data.records
    },
    // 获取随车医生--调试完成
    async getAlldoctor (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 0
        }
      )
      if (res.statusCode !== '200') return this.$message.error('获取医生列表失败')
      this.doctor = res.data.records
    },
    // 获取随车护士--调试完成
    async getAllnurse (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 1
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取随车护士失败')
      this.nurse = res.data.records
    },
    // 获取所有搬抬人员--调试完成
    async getAlllifting (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 3
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取搬抬人员失败')
      this.lifting = res.data.records
      // console.log(res);
    },
    // 获取所有护送员--调试完成
    async getAllEscorts (v) {
      // console.log(this.allotForm.orderCompany);
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 2
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取护送员失败')
      this.escorts = res.data.records
      // console.log(res);
    },
    invoiceClick (row) {
      // 是否开发票
      console.log(row)
      this.invoiceShow = true
      this.rows = row
    },
    invoiceChange () {
      if (!this.invoiceValue) {
        return this.$message.error('请选择是否开发票')
      }
      this.$http
        .post('/userManageServer/pay/updateOrder', {
          invoice: this.invoiceValue,
          orderNo: this.rows.orderNo
        })
        .then((res) => {
          this.$message.success(res.data.resultDesc)
          this.invoiceShow = false
          this.getOrderList()
          console.log(res, '请选择是否开发票')
        })
    },
    invoiceSelect (val) {
      console.log(val)
      this.invoiceValue = val
    }
  }
}
</script>

<style lang="less" scoped>
.instantOrder {
  width: 100%;
  height: 100%;

  .father-card {
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;

        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }

        .current-row > td {
          background-color: #66b1ff !important;
        }
      }

      .el-pagination {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }

      .order-dialog {
        .xbrogcorf {
          //   background-color: #fff;
          display: flex;
          border-bottom: #f3f6fa 2px solid;
          .order-title {
            display: flex;
            align-items: center;
            padding: 10px;
          }
          input {
            border: 0;
            outline: none;
            padding-left: 20px;
            // margin-bottom: 0 !important;
          }
        }

        .dialog-Title {
          color: #0097fe;
          font-size: 20px;
          font-weight: 700;
        }

        /deep/ .el-form {
          background-color: #f3f6fa;
          padding: 20px;

          .subtitle {
            color: #0097fe;
            font-size: 14px;
            font-weight: 700;
            // margin: 15px 0;
            margin-bottom: 20px;
            padding-left: 6px;
            border-left: 5px solid #0097fe;
          }

          input {
            // border-top: 0px;
            // border-right: 0px;
            // border-left: 0px;
            // border-bottom: 0px;
            background-color: #fff;
            font-weight: 700;
            color: #666666;
            // margin-bottom: 30px;
          }

          .addresscolor {
            font-size: 18px;
            // input{
            //     border-top: 0px;
            //     border-right: 0px;
            //     border-left: 0px;
            //     background-color: #fff;
            // }
          }

          .ordercolor {
            // background-color: #fff;
          }

          .fpddcolor {
            background-color: #f3f6fa;
            padding: 10px 10px;
            // line-height: 30px;
            font-weight: bold;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            .fpddcolor-title {
              border-bottom: 1px solid #666666;
              font-size: 18px;
              padding-bottom: 10px;
            }

            .fpddcolor-top {
              display: flex;
              justify-content: space-between;
              // margin-top: 10px;
              font-size: 18px;
            }

            .fpddcolor-in {
              // font-size: 16px;
            }

            .cflex {
              display: flex;
              .cflex-lable {
                background-color: #f3f6fa;
                padding: 10px;
                width: 100px;
                text-align: center;
              }
            }

            .fpddcolor-low {
              font-size: 18px;
              padding-bottom: 10px;

              img {
                width: 20px;
                height: 20px;
              }
            }
          }

          .daijiaocolor {
            display: flex;
            font-size: 18px;
            height: 45px;
            line-height: 45px;

            // justify-content: space-between;
            .daijiaocolor-left {
              margin-right: 20px;
            }

            img {
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }

            input {
              height: 45px;
              width: 300px;
              border-top: 0px;
              border-left: 0px;
              border-right: 0px;
            }
          }

          .el-row {
            // flex: 1;
            display: flex;

            // justify-content: space-between;
            .el-form-item {
              // width: 50%;
              flex: 1;
              display: flex;

              // text-align: left;
              .el-form-item__content {
                flex: 1;

                .el-input {
                  width: 100%;

                  .el-input__inner {
                    width: 100%;
                  }
                }

                .el-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.orderHospName {
  // font-size:22px;
  ::v-deep .el-input__inner {
    color: #ff7f50 !important;
  }
}
.endAddress {
  // font-size: 10px;
  color: #666666;
}
.host-name-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.row-style {
  background-color: #fff;
  display: flex;
  border-bottom: #f3f6fa 2px solid;
}
.cost-style {
  background-color: #f3f6fa;
  justify-content: space-between;
}
.cost-box {
  width: 30%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 30px;
  .cflex-lable {
    color: #0097fe;
    padding: 10px;
    width: 100px;
    text-align: center;
  }
}
.cost-box:nth-last-of-type(1) {
  margin-right: 0;
}
.order-row {
  margin-bottom: 30px;
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
